import {devices} from "../../utils/constant";

/**
 * Scrolling up increases the class, scrolling down removes the class
 * @type {number}
 */
if (devices.test(navigator.userAgent)) {

    const mobileFixedMenu = document.getElementById('mobile-fixed-menu'),
        headerFlexBox = document.getElementById('header-flex-box'),
        modalFilter = document.getElementById('modal-filter'),
        mobileFixedCategories = document.getElementById('mobile-fixed-categories'),
        sidebarFilterGroup = document.getElementById('sidebar-filter-group')

    const headerWowMobileStickySearch = document.querySelector('.header-wow-mobile-sticky-search'),
        ajaxLiveSearch = document.querySelector('.ajax-live-search')

    const pathname = window.location.pathname

    if (
        mobileFixedMenu &&
        headerFlexBox
    ) {

        let lastScrollTop = 0

        const body = document.body

        if (pathname !== '/prodajem/nekretnine/stan/maps') {
            window.addEventListener('scroll', () => {
                const scrollTop = window.scrollY

                if (
                    mobileFixedMenu.classList.contains('show') ||
                    mobileFixedCategories.classList.contains('show') ||
                    sidebarFilterGroup &&
                    sidebarFilterGroup.classList.contains('show')
                ) {
                    body.classList.add('scroll-fixed-menu')
                    headerFlexBox.classList.remove('search-show')
                } else {
                    body.classList.remove('scroll-fixed-menu')
                }

                if (modalFilter && modalFilter.classList.contains('show')) {
                    body.classList.add('scroll-fixed-filter')
                    headerFlexBox.classList.remove('search-show')
                } else {
                    document.body.classList.remove('scroll-fixed-filter')
                }

                if (scrollTop > lastScrollTop) {
                    body.classList.add('scroll-up')
                    headerFlexBox.classList.remove('search-show')
                } else {
                    body.classList.remove('scroll-up')
                }

                if (scrollTop > 300) {
                    body.classList.add('show-store-ad')
                } else {
                    if (body.classList.contains('show-store-ad')) body.classList.remove('show-store-ad')
                }

                if (
                    headerWowMobileStickySearch &&
                    headerWowMobileStickySearch.classList.contains('searching')
                ) {
                    headerWowMobileStickySearch.classList.remove('searching')
                    ajaxLiveSearch.value = ``
                }

                lastScrollTop = scrollTop <= 0 ? 0 : scrollTop
            })
        }

        mobileFixedMenu.addEventListener('click', e => hideMenu(e))

        mobileFixedCategories.addEventListener('click', e => hideMenu(e))
    }

}

/**
 * Opens the category menu
 * and closes everything else
 * @param e
 */
const hideMenu = (e) => {
    const headerFlexBox = document.getElementById('header-flex-box'),
        toolbarLinkCategory = document.getElementById('toolbar-link-category'),
        mobileFixedMenu = document.getElementById('mobile-fixed-menu'),
        mobileFixedCategories = document.getElementById('mobile-fixed-categories')

    if (!e.target.closest('.mobile-fixed-menu-content')) {
        if (headerFlexBox.classList.contains('search-show')) headerFlexBox.classList.remove('search-show')
        if (toolbarLinkCategory.classList.contains('show')) toolbarLinkCategory.classList.remove('show')
        if (mobileFixedMenu.classList.contains('show')) mobileFixedMenu.classList.remove('show')
        if (mobileFixedCategories.classList.contains('show')) mobileFixedCategories.classList.remove('show')
    }
}
