import CryptoJS from "crypto-js";

export const singleton = (url) => {
    const params = JSON.stringify({
        url: url,
        prefix: 'YW2iPx6J8JbOzN3yzd83gVGjXB4J1DWaEGDB2wW87tpg6muD4aVkigJXMlW8tcIg'
    })

    return CryptoJS.SHA256(params).toString()
}
