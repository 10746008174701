import {notificationError} from "../utils/notification-error";
import axios from "axios";
import Cookies from "js-cookie";

/**
 * Global link api
 */
// const api = 'http://localhost:8000/api/v1'
const api = 'https://chat.hitno.com/api/v1'

/**
 * Get token authorization
 */
const token = Cookies.get('hitno_token')

/**
 * Main headers authorization
 */
const headers = {
    headers: {
        Authorization: `Bearer ${token}`
    }
}

/**
 * We get the data of users with
 * whom we communicated
 * @param sender
 * @param take
 * @param skip
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getRecipients = async (sender, take, skip) => {
    try {
        return await axios.post(`${api}/recipient/all`, {
            sender: sender,
            take: take,
            skip: skip
        }, headers)
    } catch (error) {
        console.log(error)
        notificationError()
    }
}

/**
 *
 * @param room
 * @param take
 * @param skip
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getMessageAll = async (room, take, skip) => {
    try {
        return await axios.get(`${api}/messages`, {
            params: {
                room: room,
                take: take,
                skip: skip
            },
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
    } catch (error) {
        notificationError()
        console.log(error)
    }
}

/**
 *
 * @param recipient
 * @param sender
 * @param message
 * @returns {Promise<AxiosResponse<any>>}
 */
export const updateMessageView = async (recipient, sender, message) => {
    try {
        return await axios.put(`${api}/messages/view`, {
            recipient: recipient,
            sender: sender,
            message: message
        }, headers)
    } catch (error) {
        notificationError()
        console.log(error)
    }
}

/**
 *
 * @param recipient
 * @param sender
 * @param take
 * @param skip
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getMessagePagination = async (recipient, sender, take, skip) => {
    try {
        return await axios.post(`${api}/messages/pagination`, {
            recipient: recipient,
            sender: sender,
            take: take,
            skip: skip
        }, headers)
    } catch (error) {
        notificationError()
        console.log(error)
    }
}

/**
 *
 * @param recipient
 * @param sender
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getBlockedUser = async (recipient, sender) => {
    try {
        return await axios.post(`${api}/blocked`, {
            block: recipient,
            id: sender
        }, headers)
    } catch (error) {
        notificationError()
        console.log(error)
    }
}

/**
 *
 * @param recipient
 * @param sender
 * @returns {Promise<AxiosResponse<any>>}
 */
export const postBlockedUser = async (recipient, sender) => {
    try {
        return await axios.post(`${api}/blocked/store`, {
            block: recipient,
            id: sender
        }, headers)
    } catch (error) {
        notificationError()
        console.log(error)
    }
}

/**
 *
 *
 *
 * @param id
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getUnread = (id) => {
    try {
        return axios.post(`${api}/unread`, {
            sender: id
        }, headers)
    } catch (error) {
        console.log(error)
    }
}

/**
 *
 * @returns {Promise<AxiosResponse<any>>}
 * @param chatId
 */
export const updateViewMessage = (chatId) => {
    try {
        return axios.post(`${api}/messages/total-status`, {
            chatId
        }, headers)
    } catch (error) {
        console.log(error)
    }
}

/**
 *
 * @returns {number|Promise<AxiosResponse<any>>}
 */
export const getUnreadChatLength = () => {
    try {
        return axios.get(`${api}/unread`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
    } catch (error) {
        console.log(error)
        return 0
    }
}

export const getChat = (take, skip) => {
    try {
        return axios.get(`${api}/chat`, {
            params: {
                take: take,
                skip: skip
            },
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
    } catch (error) {
        console.log(error)
        return 0
    }
}

















