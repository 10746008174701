import {getUnread} from "../services/chat-services";

document.addEventListener("DOMContentLoaded", e => {

    const toolbarLinkProfile = document.querySelector('.toolbar-link-profile')

    if (true) return false

    getUnread(toolbarLinkProfile.dataset.id).then(e => {
        if (e.data) {
            document.querySelector('.BadgePulse').style.display = 'flex'
            document.querySelector('.BadgePulse').innerHTML = e.data
        }
    })

})
