import Cookies from "js-cookie";
import {DOMAIN} from "./constant";
import {fadeIn} from "../plugins/modals/fade-in";
import {DialogClose} from "../plugins/modals/dialog-close";
import {i18Translate} from "../utils/i18-init"

document.addEventListener("DOMContentLoaded", e => {
    if (!document.querySelector('.modal-cookie')) return false

    document.querySelector('.modal-cookie .accept').addEventListener('click', e => {
        Cookies.set('hitno-cookie', 1, {
            expires: 7,
            domain: DOMAIN,
            path: '/'
        })

        document.querySelector('.modal-cookie').remove()
        localStorage.setItem('hitno_cookie', 'accept')
    })

    document.querySelector('.modal-cookie .reject').addEventListener('click', e => {
        Cookies.set('hitno-cookie', 1, {
            expires: 7,
            domain: DOMAIN,
            path: '/'
        })

        document.querySelector('.modal-cookie').remove()
        localStorage.setItem('hitno_cookie', 'reject')
    })

    document.querySelector('.modal-cookie .setting').addEventListener('click', e => {

        const body = document.querySelector('body')

        const dialogHTML = `
            <div class="modal-custom-bg block-manager-cookie show">
                <div class="modal-custom">
                    <span class="modal-custom-close"><i class="las la-times"></i></span>
                    <div class="">
                        <h4 class="">${i18Translate('block-manager-cookie.title')}</h4>
                        <p class="">${i18Translate('block-manager-cookie.description')}</p>
                        <div class="">
                            <div class="custom-control custom-checkbox">
                                <input type="checkbox" class="custom-control-input" name="necessarily" id="necessarily" checked disabled>
                                <label class="custom-control-label" for="necessarily">${i18Translate('modal-cookie.required')}</label>
                            </div>
                            <div class="custom-control custom-checkbox">
                                <input type="checkbox" class="custom-control-input" name="functional" id="functional" checked>
                                <label class="custom-control-label" for="functional">${i18Translate('modal-cookie.functional-cookies')}</label>
                            </div>
                        </div>
                        <button class="btn cooki-all">${i18Translate('modal-cookie.accept-all')}</button>
                    </div>
                </div>
            </div>
        `

        body.insertAdjacentHTML('beforeend', dialogHTML)

        const blockManagerCookie = body.querySelector('.block-manager-cookie')

        fadeIn({
            dialog: blockManagerCookie,
            duration: 200
        })

        DialogClose({
            dialog: blockManagerCookie
        })

        document.querySelector('.cooki-all').addEventListener('click', e => {
            Cookies.set('hitno-cookie', 1, {
                expires: 7,
                domain: DOMAIN,
                path: '/'
            })

            document.querySelector('.block-manager-cookie').remove()
            document.querySelector('.modal-cookie').remove()
            localStorage.setItem('hitno_cookie', 'accept')
        })

    })

})
