document.addEventListener("DOMContentLoaded", e => {

    if (document.querySelectorAll('.modal-custom-bg').length === 0) return false

    const toggleModal = document.querySelectorAll('.toggle-modal'),
        modalCustomBg = document.querySelectorAll('.modal-custom-bg')

    toggleModal.forEach(el => el.addEventListener('click', e => {
        const id = e.target.closest('.toggle-modal').dataset.modal,
            modal = document.getElementById(id)

        if (modal) modal.classList.add('show')
    }))

    document.querySelectorAll('.modal-custom-close').forEach(el => el.addEventListener('click', e => {
        if (document.body.classList.contains('block-scroll')) {
            document.body.classList.remove('block-scroll')
        }

        if (e.target.closest('.modal-custom-close')) {
            e.target.closest('.modal-custom-bg').classList.remove('show')
        }
    }))

    modalCustomBg.forEach(el => el.addEventListener('click', e => {

        console.log(1)

        if (
            !e.target.closest('.modal-custom') &&
            !e.target.closest('.lSAction') &&
            !e.target.closest('.custom-dots') &&
            !e.target.closest('.swiper-dots') &&
            !e.target.closest('.swiper-pagination') &&
            !e.target.closest('.swiper-pagination-bullet') &&
            !e.target.closest('.swiper-custom-dots') &&
            e.target.closest('.modal-custom-bg')
        ) {
            if (document.body.classList.contains('block-scroll')) {
                document.body.classList.remove('block-scroll')
            }

            e.target.closest('.modal-custom-bg').classList.remove('show')
        }
    }))

})
