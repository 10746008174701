import {DOMAIN} from "../../utils/constant";
import Cookies from "js-cookie";

document.addEventListener("DOMContentLoaded", e => {

    /** Testing Menu **/
    const mobileFixedMenuProfile = document.querySelector('.mobile-fixed-menu-profile')

    if (mobileFixedMenuProfile) {

        const mobileFixedMenuList = mobileFixedMenuProfile.querySelectorAll('.mobile-fixed-menu-list .la-angle-down')

        const mobileFixedCountry = document.querySelector('.mobile-fixed-country'),
            mobileFixedLang = document.querySelector('.mobile-fixed-lang'),
            mobileFixedCategory = document.querySelector('.mobile-fixed-category')

        const fullViewCategory = document.querySelector('.mobile-fixed-menu-profile #full-view-category')

        /**
         *
         */
        document.querySelector('.mobile-fixed-below .toggle-menu').addEventListener('click', e => {
            e.preventDefault()

            document.body.classList.toggle('block-scroll')
            mobileFixedMenuProfile.classList.toggle('show')
        })
        mobileFixedMenuList.forEach(el => el.addEventListener('click', e => {
            e.target.closest('.dropdown-box-list-nested-toggle').classList.toggle('show')
        }))

        /**
         *
         */
        document.querySelector('.mobile-fixed-menu-profile-auth-tool-country').addEventListener('click', e => {
            mobileFixedCountry.classList.add('show')
        })
        mobileFixedCountry.querySelector('.mobile-fixed-country-close').addEventListener('click', e => {
            if (mobileFixedCountry.classList.contains('show')) {
                mobileFixedCountry.classList.remove('show')
            }
        })

        /**
         *
         */
        document.querySelector('.mobile-fixed-menu-profile-auth-tool-lang').addEventListener('click', e => {
            mobileFixedLang.classList.add('show')
        })
        mobileFixedLang.querySelector('.mobile-fixed-lang-close').addEventListener('click', e => {
            if (mobileFixedLang.classList.contains('show')) {
                mobileFixedLang.classList.remove('show')
            }
        })

        /**
         *
         */
        fullViewCategory.addEventListener('click', e => {
            mobileFixedCategory.classList.add('show')
        })
        mobileFixedCategory.querySelector('.mobile-fixed-category-close').addEventListener('click', e => {
            if (mobileFixedCategory.classList.contains('show')) {
                mobileFixedCategory.classList.remove('show')
            }
        })
        mobileFixedCategory.querySelectorAll('.la-angle-right').forEach(el => el.addEventListener('click', e => {
            e.preventDefault()

            e.target.closest('.item-category').classList.toggle('show')
        }))

        /**
         *
         */
        mobileFixedCountry.querySelectorAll('.mobile-fixed-country-list .countries-item').forEach(el => el.addEventListener('click', e => {
            const countryItem = e.target.closest('.countries-item'),
                countryId = Number(countryItem.dataset.id)

            const country = Cookies.get('hitno_country')

            if (Number(country) !== countryId) {
                Cookies.remove('hitno_city')
                Cookies.remove('hitno_country')

                Cookies.set('hitno_country', countryId, {
                    expires: 7,
                    domain: DOMAIN,
                    path: '/'
                })

                location.reload()
            } else {
                if (mobileFixedCountry.classList.contains('show')) {
                    mobileFixedCountry.classList.remove('show')
                }
            }
        }))

        /**
         *
         */
        mobileFixedLang.querySelectorAll('.mobile-fixed-lang-list .lang-item').forEach(el => el.addEventListener('click', e => {
            const lang = e.target.closest('.lang-item'),
                langCode = lang.dataset.code

            const language = Cookies.get('hitno_language')

            if (langCode !== language) {
                Cookies.set('hitno_language', langCode, {
                    expires: 7,
                    domain: DOMAIN,
                    path: '/'
                })

                window.location.href = `/locale/${langCode}`
            } else {
                if (mobileFixedLang.classList.contains('show')) {
                    mobileFixedLang.classList.remove('show')
                }
            }

        }))

    }
    /** Testing Menu **/


    const toolbarDropdownMenu = document.getElementById('toolbar-dropdown-menu')

    if (!toolbarDropdownMenu) return false

    toolbarDropdownMenu.addEventListener('click', e => {
        e.target.closest('.toolbar-dropdown').classList.toggle('show')
    })

    document.querySelectorAll('.dropdown-box-list-nested-toggle').forEach(el => el.addEventListener('click', e => {
        e.target.closest('.dropdown-box-list-nested-toggle').classList.toggle('show')
    }))

})
