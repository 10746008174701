import {DOMAIN} from "../../utils/constant";
import {fadeIn} from "../../plugins/modals/fade-in";
import {DialogClose} from "../../plugins/modals/dialog-close";
import SlimSelect from "slim-select";
import {notificationError} from "../../utils/notification-error";
import Cookies from "js-cookie";
import {i18Translate} from "../../utils/i18-init";

document.addEventListener("DOMContentLoaded", e => {

    const catalogFilterOpen = document.getElementById('catalog-filter-open'),
        mobileFixedMenu = document.getElementById('mobile-fixed-menu'),
        mobileFixedCategoryClose = document.querySelector('.mobile-fixed-menu-head-category-close'),
        mobileFixedCategories = document.getElementById('mobile-fixed-categories')

    const searchControlLocale = document.getElementById('search-control-locale')

    const ajaxLiveSearch = document.querySelector('.ajax-live-search'),
        searchControlGeo = document.getElementById('search-control-geo')

    const mobileFixedMenuContentCountries = document.querySelector('.mobile-fixed-menu-content-countries')

    /**
     * Open or Close filter button
     */
    if (catalogFilterOpen) {
        const sidebarFilterGroup = document.getElementById('sidebar-filter-group'),
            modalFilter = document.getElementById('modal-filter')

        catalogFilterOpen.addEventListener('click', e => {
            document.body.classList.toggle('block-scroll')
            if (sidebarFilterGroup && !modalFilter) sidebarFilterGroup.classList.toggle('show')
            if (sidebarFilterGroup && modalFilter) modalFilter.classList.toggle('show')
        })
    }

    /**
     * Open or Close menu mobile
     */
    if (mobileFixedMenu) {
        document.getElementById('full-view-category').addEventListener('click', e => {
            e.preventDefault()
            if (mobileFixedMenu.classList.contains('show')) mobileFixedMenu.classList.remove('show')
            mobileFixedCategories.classList.add('show')
        })

        if (document.querySelector('.mobile-fixed-menu-head-close')) {
            document.querySelector('.mobile-fixed-menu-head-close').addEventListener('click', e => {
                if (mobileFixedMenu.classList.contains('show')) mobileFixedMenu.classList.remove('show')
            })
        }

        mobileFixedMenu.querySelectorAll('.dropdown-box-list-nested-toggle').forEach(el => el.addEventListener('click', e => {
            e.target.closest('.dropdown-box-list-nested-toggle').classList.toggle('show')
        }))
    }

    /**
     * Open or Close category mobile
     */
    if (mobileFixedCategoryClose) {
        mobileFixedCategoryClose.addEventListener('click', e => {
            if (mobileFixedCategories.classList.contains('show')) mobileFixedCategories.classList.remove('show')
        })
    }

    if (mobileFixedCategories) {
        mobileFixedCategories.querySelector('.mobile-fixed-menu-content-category').addEventListener('click', e => {
            e.preventDefault()

            const category = e.target.closest('.mobile-fixed-menu-content-category'),
                itemCategory = e.target.closest('.item-category')

            if (e.target.closest('.category-name')) {
                category.classList.toggle('show')
                itemCategory.classList.toggle('show')
            }

            if (e.target.closest('.body-category')) {
                window.location.href = e.target.getAttribute('href')
            }
        })
    }

    if (searchControlLocale) {

        const body = document.querySelector('body')

        const dataLocale = document.querySelector('[data-locale]')

        const dataLanguage = document.querySelector('[data-language]'),
            language = JSON.parse(dataLanguage.innerHTML)

        let languageOptions = ``

        for (let i = 0; language.length > i; i++) {
            languageOptions += `
                <option
                    value="${language[i]['code']}"
                    data-icon="${language[i]['icon']}"
                    ${(language[i]['code'] === searchControlLocale.getAttribute('data-current-language-code')) ? 'selected' : ''}
                >${language[i]['name']}</option>`
        }

        searchControlLocale.addEventListener('click', e => {

            const dialogHTML = `
                <div class="modal-custom-bg block-country">
                    <div class="modal-custom">
                        <span class="modal-custom-close">
                            <i class="las la-times"></i>
                        </span>
                        <div class="modal-custom-head">
                            <h4>${i18Translate('header-catalogy-mobile.choose-your-location')}</h4>
                        </div>
                        <div class="modal-custom-body">
                            <div class="mb-3">
                                <span>${i18Translate('header-catalogy-mobile.your-location')}</span>
                                <select name="country" class="select-country" id="country">${countryOptions}</select>
                            </div>
                            <div>
                                <span>${i18Translate('header-catalogy-mobile.your-localization')}</span>
                                <select name="language" class="select-language" id="language">${languageOptions}</select>
                            </div>
                        </div>
                        <div class="modal-view-phone-display">
                            <button class="btn-custom btn-color-blue">${i18Translate('header-catalogy-mobile.choose')}</button>
                        </div>
                    </div>
                </div>
            `

            body.insertAdjacentHTML('beforeend', dialogHTML)

            const country = body.querySelector('.block-country'),
                button = country.querySelector('button'),
                selectLanguages = new SlimSelect({
                    select: '.block-country .select-language',
                    settings: {
                        showSearch: false,
                        placeholderText: i18Translate('header-catalogy-mobile.choose-brand'),
                        searchText: i18Translate('header-catalogy-mobile.nothing-was-found'),
                        searchPlaceholder: i18Translate('constant.search'),
                    }
                }),
                selectCountry = new SlimSelect({
                    select: '.block-country .select-country',
                    settings: {
                        showSearch: false,
                        placeholderText: i18Translate('header-catalogy-mobile.choose-brand'),
                        searchText: i18Translate('header-catalogy-mobile.nothing-was-found'),
                        searchPlaceholder: i18Translate('constant.search'),
                    }
                })

            fadeIn({
                dialog: country,
                duration: 200
            })

            DialogClose({
                dialog: country
            })

            button.addEventListener('click', () => resetLocaleAndLanguage(selectLanguages, selectCountry))

        })

    }

    if (mobileFixedMenuContentCountries) {

        mobileFixedMenuContentCountries.querySelector('.countries').addEventListener('click', e => {
            mobileFixedMenuContentCountries.classList.toggle('show')
        })

        mobileFixedMenuContentCountries.querySelectorAll('.countries-body .countries-item').forEach(el => el.addEventListener('click', e => {
            const countriesItem = e.target.closest('.countries-item')

            if (mobileFixedMenuContentCountries.classList.contains('show')) {
                mobileFixedMenuContentCountries.classList.remove('show')
            }

            const country = Cookies.get('hitno_country')

            if (country && Number(country) !== Number(countriesItem.dataset.id)) {
                Cookies.remove('hitno_city')
            }

            if (country) {
                Cookies.remove('hitno_country')
            }

            Cookies.set('hitno_country', countriesItem.dataset.id, {
                expires: 7,
                domain: DOMAIN,
                path: '/'
            })

            location.reload()
        }))

    }

})

const resetLocaleAndLanguage = (languages, country) => {
    let languageSelected = null,
        languagesOptions = languages.getData(),
        countrySelected = null,
        countryOptions = country.getData()

    for (let i = 0; countryOptions.length > i; i++) {
        if (countryOptions[i]['selected']) {
            countrySelected = countryOptions[i]
        }
    }

    for (let i = 0; languagesOptions.length > i; i++) {
        if (languagesOptions[i]['selected']) {
            languageSelected = languagesOptions[i]
        }
    }

    if (!languageSelected || !countrySelected) {
        notificationError(i18Translate('header-catalogy-mobile.sorry-try-again-later'))
        return false
    }

    Cookies.set('hitno_location', countrySelected.data.name, {
        expires: 7,
        domain: DOMAIN,
        path: '/'
    })

    Cookies.set('hitno_language', languageSelected.value, {
        expires: 7,
        domain: DOMAIN,
        path: '/'
    })

    window.location.href = `/locale/${languageSelected.value}`

}



















