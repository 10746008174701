import Cookies from "js-cookie";
import {DOMAIN} from "../constant";

export const addCityCookie = (el) => {

    Cookies.set('hitno_city', JSON.stringify({id: el.dataset.city, name: el.innerHTML}), {
        expires: 7,
        domain: DOMAIN,
        path: '/'
    })

    location.reload()
}

export const addCountryCookie = (el) => {
    const country = Cookies.get('hitno_country')

    if (country && Number(country) !== Number(el.dataset.country)) {
        Cookies.remove('hitno_city')
    }

    if (country) {
        Cookies.remove('hitno_country')
    }

    Cookies.set('hitno_country', el.dataset.country, {
        expires: 7,
        domain: DOMAIN,
        path: '/'
    })

    location.reload()
}
