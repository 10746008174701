document.addEventListener("DOMContentLoaded", e => {

    const toolbarLinkCategory = document.getElementById('toolbar-link-category')

    if (!toolbarLinkCategory) return false

    toolbarLinkCategory.addEventListener('click', e => {
        document.getElementById('mobile-fixed-menu').classList.toggle('show')
    })

})
