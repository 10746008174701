import {notificationError} from "../utils/notification-error";
import axios from "axios";

/**
 * Adds ad to favorites
 * @param {number|string} id
 * @param {number|string} status
 * @returns {Promise<AxiosResponse<any>>}
 */
export const storeFavorite = async (id, status) => {
    try {
        return await axios.post(`/profile/favorites/add`, {
            id: id,
            status: status
        }, {
            headers: {
                'Accept-Language': document.querySelector('html').getAttribute('lang')
            }
        })
    } catch (error) {
        //notificationError()
        console.log(error)
    }
}

/**
 *
 */
export const getFavoritePagination = async (take, skip) => {
    try {
        return await axios.get(`/profile/favorites/pagination/${take}/${skip}`, {
            headers: {
                'Accept-Language': document.querySelector('html').getAttribute('lang')
            }
        })
    } catch (error) {
        notificationError()
        console.log(error)
    }
}
