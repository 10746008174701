import axios from "axios";
import {notificationError} from "../utils/notification-error";
import {singleton} from "../utils/singleton";

const api = '/api/v1/cities'

/**
 * Search name city
 * @param {string} name
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getSearchCity = async ({value, countryId}) => {
    try {
        return axios.get(`${api}/search`, {
            params: {
                name: value,
                country_id: countryId
            },
            headers: {
                'x-singleton-hitno': singleton(`${api}/search`),
                'Accept-Language': document.querySelector('html').getAttribute('lang')
            }
        })
    } catch (error) {
        console.log(error)
        notificationError()
    }
}

/**
 * Makes a city id search by city name
 * @param {string} id
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getCityId = (id) => {
    try {
        return axios.get(`${api}/search/id`, {
            params: {
                id: id
            },
            headers: {
                'x-singleton-hitno': singleton(`${api}/search/id`),
                'Accept-Language': document.querySelector('html').getAttribute('lang')
            }
        })
    } catch (error) {
        console.log(error)
        notificationError()
    }
}

/**
 * Sends coordinates for reverse geocoding
 * @param {number} lat
 * @param {number} lon
 */
export const getSearchAddressCoordinates = (lat, lon) => {
    try {
        return axios.get(`/api/v1/geocoding/reverse`, {
            params: {
                lat: lat,
                lon: lon,
                format: 'geojson',
                addressdetails: 1
            },
            headers: {
                'accept-language': 'sr-Latn;',
                'x-singleton-hitno': singleton(`/api/v1/geocoding/reverse`)
            }
        })
    } catch (error) {
        console.log(error)
        notificationError()
    }
}

/**
 * Search for a city in the database
 * based on the entered search query
 * @param {string} name
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getSearchCityNameFull = async ({name, countryId}) => {
    try {
        return axios.get(`${api}/search/name`, {
            params: {
                name: name,
                country_id: countryId
            },
            headers: {
                'x-singleton-hitno': singleton(`${api}/search/name`),
                'Accept-Language': document.querySelector('html').getAttribute('lang')
            }
        })
    } catch (error) {
        console.log(error)
        notificationError()
    }
}

/**
 * Search for a city in the database
 * based on the entered search query
 * @param {string} name
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getSearchCityName = async ({name, countryId}) => {
    try {
        return axios.get(`${api}/search/name-compliance`, {
            params: {
                name: name,
                country_id: countryId
            },
            headers: {
                'x-singleton-hitno': singleton(`${api}/search/name-compliance`),
                'Accept-Language': document.querySelector('html').getAttribute('lang')
            }
        })
    } catch (error) {
        console.log(error)
        notificationError()
    }
}

/**
 * Sends requests for backing to search
 * for a city - district according to
 * data from the input
 * @param name
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getSearchCityEstate = ({name, countryId}) => {
    try {
        return axios.get(`${api}/search/city`, {
            params: {
                name: name,
                country_id: countryId
            },
            headers: {
                'x-singleton-hitno': singleton(`${api}/search/city`),
                'Accept-Language': document.querySelector('html').getAttribute('lang')
            }
        })
    } catch (error) {
        console.log(error)
        notificationError()
    }
}

/**
 * Searches for a district based on a
 * specified city or town
 * @param value
 * @param id
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getSearchDistrict = ({value, countryId, cityId}) => {
    try {
        return axios.post(`${api}/search/district`, {
            country_id: Number(countryId),
            city_id: Number(cityId),
            value: value
        }, {
            headers: {
                'x-singleton-hitno': singleton(`${api}/search/district`),
                'Accept-Language': document.querySelector('html').getAttribute('lang')
            }
        })
    } catch (error) {
        console.log(error)
        notificationError()
    }
}

/**
 * Displays the coordinates of the center
 * to change the map of the center when
 * filtering, when switching from a quick
 * filter to a more detailed one
 * @param name
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getSearchCityCoordinates = (name) => {
    try {
        return axios.get(`${api}/search/coordinates`, {
            params: {
                name: name
            },
            headers: {
                'x-singleton-hitno': singleton(`${api}/search/coordinates`),
                'Accept-Language': document.querySelector('html').getAttribute('lang')
            }
        })
    } catch (error) {
        console.log(error)
    }
}

/**
 *
 * @param id
 */
export const getCitiesAndParent = (id) => {
    try {
        return axios.get(`${api}/search/districts`, {
            params: {
                id: id
            },
            headers: {
                'x-singleton-hitno': singleton(`${api}/search/districts`),
                'Accept-Language': document.querySelector('html').getAttribute('lang')
            }
        })
    } catch (error) {
        notificationError()
        console.log(error)
    }
}
















