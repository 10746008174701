import {fadeIn} from "../../plugins/modals/fade-in";
import {DialogClose} from "../../plugins/modals/dialog-close";
import Cookies from "js-cookie";
import {i18Translate} from "../../utils/i18-init";
import {DOMAIN} from "../../utils/constant";

document.addEventListener("DOMContentLoaded", e => {

    const countries = document.querySelector('.header-wow-top .countries')

    if (countries) {

        const countriesHead = countries.querySelector('.countries-head')

        countriesHead.addEventListener('click', e => {
            countries.classList.toggle('show')
        })

        countries.querySelectorAll('.countries-body .countries-item').forEach(el => el.addEventListener('click', e => {

            const countriesItem = e.target.closest('.countries-item')

            if (countries.classList.contains('show')) {
                countries.classList.remove('show')
            }

            const country = Cookies.get('hitno_country')

            if (country && Number(country) !== Number(countriesItem.dataset.id)) {
                Cookies.remove('hitno_city')
            }

            if (country) {
                Cookies.remove('hitno_country')
            }

            Cookies.set('hitno_country', countriesItem.dataset.id, {
                expires: 7,
                domain: (DOMAIN === 'localhost') ? DOMAIN : 'hitno.com',
                path: '/'
            })

            location.reload()

        }))

    }

    const languages = document.querySelector('.languages'),
        mobileFixedMenuContentLanguage = document.querySelector('.mobile-fixed-menu-content-language')

    if (languages) {
        languages.querySelector('.language-head').addEventListener('click', e => {
            languages.classList.toggle('show')
        })
    }

    if (mobileFixedMenuContentLanguage) {

        const dataLanguage = document.querySelector('[data-languages]'),
            language = JSON.parse(dataLanguage.innerHTML)

        mobileFixedMenuContentLanguage.querySelector('.language').addEventListener('click', e => {

            let languagesOptions = ``

            for (let i = 0; language.length > i; i++) {
                languagesOptions += `
                    <a
                        href="/locale/${language[i]['code']}"
                        class="language-item ${(language[i]['code'] === mobileFixedMenuContentLanguage.getAttribute('data-currency-code')) ? 'selected' : ''}"
                    >
                        <i class="fi fi-${language[i]['icon']} me-1 fs-3"></i>
                        <div class="language-item-name">${language[i]['name']}</div>
                    </a>
                `
            }

            const dialogHTML = `
                <div class="modal-custom-bg block-languages">
                    <div class="modal-custom">
                        <span class="modal-custom-close">
                            <i class="las la-times"></i>
                        </span>
                        <div class="modal-custom-head">
                            <h4>${i18Translate('header-top.select-language')}</h4>
                        </div>
                        <div class="modal-custom-body">
                            <div class="languages">${languagesOptions}</div>
                        </div>
                    </div>
                </div>
            `

            document.querySelector('body').insertAdjacentHTML('beforeend', dialogHTML)

            const country = document.querySelector('body').querySelector('.block-languages')

            fadeIn({
                dialog: country,
                duration: 200
            })

            DialogClose({
                dialog: country
            })

        })

    }

})
