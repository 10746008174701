import axios from "axios";
import {i18Translate} from "../../utils/i18-init";

document.addEventListener("DOMContentLoaded", e => {

    const headerButtonMenuCatalog = document.getElementById('header-button-menu-catalog'),
        headerBigMenu = document.getElementById('header-big-menu')

    if (!headerButtonMenuCatalog || !headerBigMenu) return false

    const headerBigCategoryMenuLeft = document.querySelector('.header-big-category-menu-left'),
        isSale = headerBigCategoryMenuLeft.getAttribute('data-is-sale')

    headerButtonMenuCatalog.addEventListener('click', e => {
        headerButtonMenuCatalog.classList.toggle('active')
        headerBigMenu.classList.toggle('show')
    })

    document.querySelectorAll('.header-big-category-menu-item').forEach(el => el.addEventListener('mouseover', e => {
        e.target.closest('.header-big-category-menu-item').click()
    }))

    document.querySelectorAll('.header-big-category-menu-item').forEach(el => el.addEventListener('click', e => {
        e.preventDefault()

        const current = e.target.closest('.header-big-category-menu-item'),
            currentId = current.dataset.id

        const headerBigSubcategoryMenuList = document.querySelector('.header-big-subcategory-menu-list'),
            headerBigSubcategoryMenuListTitle = headerBigSubcategoryMenuList.querySelector('h4'),
            headerBigSubcategoryMenuListRow = headerBigSubcategoryMenuList.querySelector('.no-gutters')

        const currentMenuList = document.getElementById(`category-${currentId}`)

        if(!currentMenuList) {
            axios.get(`/category/${currentId}.json`)
                .then(e => {
                    const categoryData = e.data

                    headerBigSubcategoryMenuList.setAttribute('id', `category-${categoryData.id}`)
                    headerBigSubcategoryMenuListTitle.innerHTML = i18Translate(`category-list.${categoryData.slug}`)

                    headerBigSubcategoryMenuListRow.innerHTML = ``

                    for (let item of Object.values(categoryData.parent)) {
                        headerBigSubcategoryMenuListRow.insertAdjacentHTML('beforeend', buildCategoryParent({
                            parent: item,
                            slug: (Number(isSale)) ? categoryData.slug_en : categoryData.slug,
                            isSale: Number(isSale)
                        }))
                    }
                })
        }
    }))

    document.querySelector('.header-big-subcategory-menu-list').addEventListener('click', e => {
        if(e.target.classList.contains('header-big-sub-open')) {
            const parent = e.target.closest('.header-big-sub-subcategory'),
                children = parent.querySelectorAll('.hide')

            for (let i = 0; children.length > i; i++) {
                if (children[i].classList.contains('hide')) {
                    children[i].classList.remove('hide')
                }
            }

            e.target.remove()
        }
    })

})

const buildCategoryParent = (params) => {
    let parentHTML = ``

    const parent = params.parent,
        slug = params.slug,
        prefix = (params.isSale) ? 'sale' : 'prodajem'

    if(parent.parent && parent.parent.length) {
        for(let x = 0; parent.parent.length > x; x++) {
            const subcategorySlug = (params.isSale) ? parent.slug_en : parent.slug,
                subSubcategorySlug = (params.isSale) ? parent.parent[x].slug_en : parent.parent[x].slug

            parentHTML += `
                <a
                    href="/${prefix}/${slug}/${subcategorySlug}/sub/${subSubcategorySlug}"
                    itemprop="url"
                    data-id="${parent.parent[x].id}"
                    ${(x >= 4) ? 'class="hide"' : ''}
                >
                    ${i18Translate(`category-list.${parent.parent[x].slug}`)}
                </a>
                ${(x === 4) ? `<span class="header-big-sub-open">${i18Translate('header-menu.show-more')}</span>` : ''}
            `
        }

        parentHTML = `<div class="header-big-sub-subcategory">${parentHTML}</div>`
    }

    return `
        <div class="header-big-subcategory">
            <a href="/${prefix}/${slug}/${(params.isSale) ? parent.slug_en : parent.slug}" itemprop="url" data-id="${parent.id}">
                ${i18Translate(`category-list.${parent.slug}`)}
                <i class="las la-angle-right"></i>
            </a>
            ${parentHTML}
        </div>
    `
}






























