import Swiper from 'swiper';
import { Pagination } from 'swiper/modules';

export const initSwiper = () => {

    const swiperInit = document.querySelectorAll('.swiper-previews')

    for(let i = 0; swiperInit.length > i; i++) {
        const swiper = swiperInit[i]

        if(!swiper.classList.contains('swiper-initialized')) {
            const slider = new Swiper(swiperInit[i], {
                modules: [Pagination],
                loop: true,
                pagination: {
                    el: '.swiper-pagination'
                },
                slidesPerView: 1
            })

            swiper.addEventListener('click', (e) => {
                e.target.closest('a').click()
            })

            swiper.querySelectorAll(`.swiper-pagination-bullet`)
                .forEach((bullet, bulletIndex) => bullet.addEventListener('mouseover', e => {
                    slider.slideToLoop(bulletIndex)
                }))
        }
    }

    // const swipers = new Swiper('.swiper-previews', {
    //     modules: [Pagination],
    //     loop: true,
    //     pagination: {
    //         el: '.swiper-pagination'
    //     },
    //     slidesPerView: 1
    // })
    //
    // document.querySelectorAll('.swiper-previews').forEach((swiper, swiperIndex) => {
    //
    //     swiper.addEventListener('click', (e) => {
    //         e.target.closest('a').click()
    //     })
    //
    //     swiper.querySelectorAll(`.swiper-pagination-bullet`).forEach((bullet, bulletIndex) => bullet.addEventListener('mouseover', e => {
    //         const swiper = (swiperIndex) ? swipers[swiperIndex] : swipers[0]
    //         swiper.slideToLoop(bulletIndex)
    //     }))
    //
    // })

}
