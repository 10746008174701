export const fadeIn = ({dialog, duration}) => {
    let start = null

    function step(timestamp) {
        if (!start) start = timestamp

        let progress = timestamp - start

        dialog.style.opacity = Math.min(progress / duration, 1)

        if (progress < duration) {
            requestAnimationFrame(step)
        }
    }

    requestAnimationFrame(step)
}
