import {i18Translate} from "./i18-init";

export const DOMAIN = window.location.hostname

export const accessTokenPlanPlus = '52D2821F-E06A-4D3D-89AC-04EDA984903B'
//export const accessTokenMaptiler = 'TkVOoHFv4YYs1n1F3T6S'
export const accessTokenMaptiler = 'XRawBvgVXPg65Ud4ZXUR'

export const placeholderSlimSelect = i18Translate('constant.choosing-an-option')

export const radiusSearch = 1000

export const categories = `1007,1008,1009,1010,1012,1029,1034,1129,1315`

export const devices = new RegExp('Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini', "i")

export const waitText = i18Translate('constant.search')
export const thereNothing = i18Translate('constant.there-is-nothing')
export const applyText = i18Translate('constant.apply')

export const pageAuthorization = '/login'

export const maxFileSize = 10485760
export const maxFileLength = 9
