export const DialogClose = ({dialog}) => {

    dialog.addEventListener('click', e => {

        if (e.target.closest('.modal-custom-close')) {
            dialog.remove()

            if (document.body.classList.contains('block-scroll')) {
                document.body.classList.remove('block-scroll')
            }
        }

        if (!e.target.closest('.modal-custom')) {
            dialog.remove()

            if (document.body.classList.contains('block-scroll')) {
                document.body.classList.remove('block-scroll')
            }
        }

        if (e.target.closest('.button-click-close')) {
            dialog.remove()

            if (document.body.classList.contains('block-scroll')) {
                document.body.classList.remove('block-scroll')
            }
        }

    })

}
