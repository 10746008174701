import {notificationError} from "../utils/notification-error";
import axios from "axios";
import {singleton} from "../utils/singleton";
import {i18Translate} from "../utils/i18-init";

/**
 * Sending an authorization request
 * @param {string} email
 * @param {string} password
 * @returns {Promise<AxiosResponse<any>>}
 */
export const postLogin = async (email, password) => {
    try {
        return await axios.post(`/authorization`, {
            email: email,
            password: password
        }, {
            headers: {
                'Accept-Language': document.querySelector('html').getAttribute('lang')
            }
        })
    } catch (error) {
        notificationError(i18Translate('authorization-services.post-login'))
        console.log(error)
    }
}

/**
 * Sends an email notification with a registration code
 * @param {string} email
 * @returns {Promise<AxiosResponse<any>>}
 */
export const sendCodeEmail = async (email) => {
    try {
        return await axios.post(
            `/api/v1/authentication/register/registration-code`,
            {
                email: email
            }, {
                headers: {
                    'x-singleton-hitno': singleton(`/api/v1/authentication/register/registration-code`),
                    'Accept-Language': document.querySelector('html').getAttribute('lang')
                }
            })
    } catch (error) {
        console.log(error)
        notificationError()
    }
}

/**
 * Sends an email notification with a registration code
 * @param {string} email
 * @param code
 * @returns {Promise<AxiosResponse<any>>}
 */
export const verificationCodeEmail = async (email, code) => {
    try {
        return await axios.post(
            `/api/v1/authentication/register/verification-code`,
            {
                email: email,
                code: code
            }, {
                headers: {
                    'x-singleton-hitno': singleton(`/api/v1/authentication/register/verification-code`),
                    'Accept-Language': document.querySelector('html').getAttribute('lang')
                }
            })
    } catch (error) {
        console.log(error)
        notificationError()
    }
}

/**
 * Submits registration requests
 * @param username
 * @param {string} email
 * @param {string} password
 * @returns {Promise<AxiosResponse<any>>}
 */
export const postRegister = async (username, email, password) => {
    try {
        return await axios.post(`/register`, {
            name: username,
            email: email,
            password: password
        }, {
            headers: {
                'Accept-Language': document.querySelector('html').getAttribute('lang')
            }
        })
    } catch (error) {
        console.log(error)
        notificationError()
    }
}

/**
 *
 * @param email
 * @returns {Promise<AxiosResponse<any>>}
 */
export const postForgotPassword = async (email) => {
    try {
        return await axios.post(`/api/v1/authentication/forgot-password`, {
            email: email
        }, {
            headers: {
                'x-singleton-hitno': singleton(`/api/v1/authentication/forgot-password`),
                'Accept-Language': document.querySelector('html').getAttribute('lang')
            }
        })
    } catch (error) {
        console.log(error)
        notificationError()
    }
}

/**
 *
 * @param token
 * @param password
 * @returns {Promise<AxiosResponse<any>>}
 */
export const postResetPassword = async (token, password) => {
    try {
        return await axios.post(`/api/v1/authentication/reset-password`, {
            token: token,
            password: password
        }, {
            headers: {
                'x-singleton-hitno': singleton(`/api/v1/authentication/reset-password`),
                'Accept-Language': document.querySelector('html').getAttribute('lang')
            }
        })
    } catch (error) {
        console.log(error)
        notificationError()
        document.getElementById('reset-password-button').disabled = false
        document.getElementById('reset-password-button').innerHTML = i18Translate('authorization-service.reset-your-password')
    }
}

/**
 *
 * @returns {Promise<AxiosResponse<any>>}
 */
export const postLogout = () => {
    try {
        return axios.post(`/logout`)
    } catch (error) {
        console.log(error)
        notificationError()
    }
}






























