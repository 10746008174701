import i18next from 'i18next'
import axios from "axios";

const initI18 = async () => {
    const currentLang = document.querySelector('html').getAttribute('lang')

    let resources = {},
        locales = ['en', 'sr', 'de', 'es', 'pt', 'ru']

    // for (let i = 0; locales.length > i; i++) {
    //     await axios.get(`${window.location.origin}/locale/${currentLang}.json`).then(e => {
    //         resources[locales[i]] = e.data
    //     })
    // }

    await axios.get(`${window.location.origin}/locale/${currentLang}.json`).then(e => {
        resources[currentLang] = e.data
    })

    i18next.init({
        lng: currentLang,
        debug: false,
        resources: resources
    })



}

export const i18Translate = (key) => {
    return i18next.t(key)
}

initI18()

