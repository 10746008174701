import {devices} from "../../utils/constant";
import Cookies from "js-cookie";

document.addEventListener("DOMContentLoaded", e => {

    if (
        !devices.test(window.navigator.userAgent) ||
        !document.querySelector('.floating-menu-ad-create')
    ) {
        return false
    }

    document.querySelector('.floating-menu-ad-create').addEventListener('click', e => {

        const isAuthorization = document.querySelector('.mobile-fixed-menu-head-avatar')

        if (!isAuthorization) {
            Cookies.set(`hitno_redirect`, '/profile/ad/store')
        }

    })

})
