import {getFavoriteStatus} from "../../utils/favorite/favorite";

document.addEventListener("DOMContentLoaded", e => {

    const favorite = document.querySelectorAll('.item-grid-favorite')

    if (
        favorite.length === 0 ||
        document.getElementById('estate') ||
        document.getElementById('auto') ||
        document.getElementById('favorite') ||
        document.getElementById('category')
    ) return false

    favorite.forEach(el => el.addEventListener('click', e => {
        e.preventDefault()

        if (!e.target.closest('.preporuk-announcement')) {
            getFavoriteStatus(e.target)
        }
    }))

})
