import {addCityCookie} from "../../utils/geo/add-city-cookie";
import {
    getSearchAddressCoordinates,
    getSearchCityCoordinates,
    getSearchCityNameFull
} from "../../services/cities-services";
import Cookies from "js-cookie";
import {DOMAIN} from "../../utils/constant";
import {i18Translate} from "../../utils/i18-init"

document.addEventListener("DOMContentLoaded", e => {

    const searchControlGeo = document.getElementById('search-control-geo'),
        searchControlGeoCancel = document.getElementById('search-control-geo-cancel')

    if (!searchControlGeo || !searchControlGeoCancel) {
        return false
    }

    /**
     * El Group Box Search Input and Select city
     * @type {HTMLElement}
     */
    const headerFlexBoxTwo = document.getElementById('header-flex-box-2')

    const listCities = document.getElementById('list-cities'),
        listCitiesResult = document.getElementById('list-cities-result')

    const notificationLocationConfirmation = document.getElementById('notification-location-confirmation')

    /**
     * Open Geo - Locale Search
     */
    searchControlGeo.addEventListener('click', e => {
        if (headerFlexBoxTwo) {
            headerFlexBoxTwo.classList.toggle('search-show')
        } else {
            e.target.closest('.header-flex-box').classList.toggle('search-show')
            listCities.classList.add('show')
        }
    })

    /**
     * Close open Geo - Locale Search
     */
    searchControlGeoCancel.addEventListener('click', e => {
        if (headerFlexBoxTwo) {
            headerFlexBoxTwo.classList.toggle('search-show')
        } else {
            e.target.closest('.header-flex-box').classList.toggle('search-show')
        }
    })

    /**
     * Search to Input value Geo - Locale Show and Hide
     */
    document.getElementById('search-geo-input').addEventListener('input', e => {

        /**
         * Shows or hides the search result
         */
        if (e.target.value.length < 3) {
            if (listCitiesResult.classList.contains('show')) {
                listCitiesResult.classList.remove('show')
            }

            listCities.classList.add('show')
            return false
        } else {
            if (listCities.classList.contains('show')) listCities.classList.remove('show')
            listCitiesResult.classList.add('show')
        }

        /**
         * Includes text please wait
         */
        listCitiesResult.innerHTML = `<span class="item-city">${i18Translate('constant.search')}</span>`

        /**
         * Sends a request and displays the search
         * result for cities, districts, sub-districts
         */
        getSearchCityNameFull({
            name: e.target.value,
            countryId: searchControlGeo.dataset.country
        }).then(e => insertCitiesResult(e['data']['data'], listCitiesResult))
    })

    /**
     * On Click City on Active Site to LocalStore
     */
    document.querySelectorAll('.modal-country-container').forEach(el => el.addEventListener('click', e => {

        if (e.target.classList.contains('item-city') && !e.target.classList.contains('show-country')) {
            if (headerFlexBoxTwo) headerFlexBoxTwo.classList.toggle('search-show')

            addCityCookie(e.target)
        }
    }))

    /**
     * After a second, it will run a
     * function that checks if the
     * location is already specified,
     * and if the modal window is
     * displayed in the dom tree
     */
    setTimeout(e => {
        if (navigator.geolocation && !Cookies.get('city') && document.getElementById('notification-location-confirmation')) {
            navigator.geolocation.getCurrentPosition(geoPositionClient, null, {
                enableHighAccuracy: true,
                timeout: 5000,
                maximumAge: 0
            })
        }
    }, 1000)

    /**
     * Tracks a click on two buttons
     * in a modal window to confirm
     * a city or vice versa
     */
    if (notificationLocationConfirmation) {
        /**
         * Tracks clicks on block select from list
         */
        notificationLocationConfirmation.querySelector('#from-list').addEventListener('click', e => {
            notificationLocationConfirmation.classList.remove('show')
            document.getElementById('header-flex-box-2').classList.add('search-show')
        })

        /**
         * Tracks clicks on city confirmation buttons
         */
        notificationLocationConfirmation.querySelectorAll('.btn-custom').forEach(
            el => el.addEventListener('click', e => cityConfirms(e))
        )
    }

})


/**
 * Tracks clicks on confirmation
 * buttons or no city in the modal window
 * @param e
 */
const cityConfirms = e => {
    /**
     * We get the div of the modal
     * window and write it to a constant
     * @type {HTMLElement}
     */
    const notificationLocationConfirmation = document.getElementById('notification-location-confirmation')

    /**
     * Checks which button was clicked
     */
    if (e.target.dataset.type === 'apply') {
        let id = notificationLocationConfirmation.dataset.id,
            name = notificationLocationConfirmation.dataset.name

        Cookies.set('city', JSON.stringify({id: id, name: name}), {
            expires: 7,
            domain: DOMAIN,
            path: '/'
        })

        window.location.reload()
    } else {
        notificationLocationConfirmation.classList.remove('show')
        if (document.getElementById('header-flex-box-2')) document.getElementById('header-flex-box-2').classList.add('search-show')
        if (document.getElementById('header-flex-box')) document.getElementById('header-flex-box').classList.add('search-show')
    }
}

/**
 * Sends a request to a third-party
 * service to understand the city
 * where the client is located
 * @param position
 */
const geoPositionClient = (position) => {
    getSearchAddressCoordinates(
        position['coords']['latitude'],
        position['coords']['longitude']
    ).then(e => autoDetectedCity(e.data.data))
}

/**
 *
 * @returns {boolean}
 * @param data
 */
const autoDetectedCity = (data) => {
    if (data.hasOwnProperty('error')) return false

    /**
     * We get the div of the modal
     * window and write it to a constant
     * @type {HTMLElement}
     */
    const notificationLocationConfirmation = document.getElementById('notification-location-confirmation')

    /**
     * Creating base variables
     */
    let item = data,
        name = null

    /**
     * We check what type of data
     * came to us from api, according
     * to this we get the correct
     * name, district or locality
     */
    if (
        typeof item['properties'] !== undefined &&
        typeof item['properties']['address'] !== undefined &&
        typeof item['properties']['address']['city'] !== undefined
    ) name = item['properties']['address']['city']

    if (!name) return false

    /**
     * Removes text in brackets for
     * more precise searching
     */
    name = name.replace(/ *\([^)]*\) */g, "")

    /**
     * We send a request and then display
     * the result and show it to the user
     */
    getSearchCityCoordinates(name).then(e => {
        let data = e['data']

        /**
         * Checks if the city was found in the database
         */
        if (data['success']) {
            data = data['data']

            /**
             * Adds a class to the modal
             * and makes it visible
             */
            notificationLocationConfirmation.classList.add('show')

            /**
             * If the parent id was not passed,
             * then leave the field below empty
             */
            if (data['parent'] != null) {
                notificationLocationConfirmation.dataset.id = data['parent_id']
                notificationLocationConfirmation.dataset.name = data['parent_name']
                notificationLocationConfirmation.querySelectorAll('h4')[0].innerHTML = `${i18Translate('header-geo.your-city')} ${data['parent_name']}?`
            } else {
                notificationLocationConfirmation.dataset.id = data['id']
                notificationLocationConfirmation.dataset.name = data['name']
                notificationLocationConfirmation.querySelectorAll('h4')[0].innerHTML = `${i18Translate('header-geo.your-city')} ${data['name']}?`
            }
        } else {
            return false
        }
    })
}

/**
 * Outputs the result of cities in a block
 * @param data
 * @param group
 */
const insertCitiesResult = (data, group) => {
    group.innerHTML = ``

    /**
     * Displays what is not found
     */
    if (data.length === 0) {
        group.insertAdjacentHTML('beforeend', `<span class="item-city">${i18Translate('constant.there-is-nothing')}</span>`)
    }

    /**
     * Displays all found cities
     */
    for (let i = 0; data.length > i; i++) {
        let template = `<span class="item-city" data-city="${data[i]['id']}">${data[i]['name']}</span>`

        group.insertAdjacentHTML('beforeend', template)

        if (i >= 20) break
    }
}























