import {notificationError} from "../utils/notification-error";
import axios from "axios";
import {singleton} from "../utils/singleton";

const api = `/api/v1/search`

export const searchAd = ({search, countryId, isSale}) => {
    try {

        return axios.get(api, {
            params: {
                search: search,
                country_id: countryId,
                is_sale: isSale
            },
            headers: {
                'x-singleton-hitno': singleton(api),
                'Accept-Language': document.querySelector('html').getAttribute('lang'),
            }
        })
    } catch (error) {
        notificationError()
        console.log(error)
    }
}

export const searchProduct = ({search, countryId, signal}) => {
    try {

        return axios.get(`${api}/product`, {
            params: {
                search: search,
                country_id: countryId
            },
            cancelToken: signal,
            headers: {
                'x-singleton-hitno': singleton(`${api}/product`),
                'Accept-Language': document.querySelector('html').getAttribute('lang'),
            }
        })
    } catch (error) {
        notificationError()
        console.log(error)
    }
}

export const searchFilterLengthButton = ({
                                             search,
                                             categorySlug,
                                             subcategorySlug,
                                             subsubcategorySlug,
                                             json,
                                             countryId
                                         }) => {
    try {
        return axios.post(`${api}/filter/count`, {
            search: search,
            category_slug: categorySlug,
            subcategory_slug: subcategorySlug,
            subsubcategory_slug: subsubcategorySlug,
            json: json,
            country_id: countryId
        }, {
            headers: {
                'x-singleton-hitno': singleton(`${api}/filter/count`),
                'Accept-Language': document.querySelector('html').getAttribute('lang')
            }
        })
    } catch (error) {
        notificationError()
        console.log(error)
    }
}

export const searchFilter = ({
                                 search,
                                 categorySlug,
                                 subcategorySlug,
                                 subsubcategorySlug,
                                 json,
                                 page,
                                 sort,
                                 countryId
                             }) => {
    try {
        return axios.post(`${api}/filter`, {
            search: search,
            category_slug: categorySlug,
            subcategory_slug: subcategorySlug,
            subsubcategory_slug: subsubcategorySlug,
            json: json,
            page: page,
            sort: sort,
            country_id: countryId
        }, {
            headers: {
                'x-singleton-hitno': singleton(`${api}/filter`),
                'Accept-Language': document.querySelector('html').getAttribute('lang')
            }
        })
    } catch (error) {
        notificationError()
        console.log(error)
    }
}

export const searchFilterProductsTotal = ({
                                              countryId,
                                              search,
                                              categorySlug,
                                              subcategorySlug,
                                              subsubcategorySlug,
                                              json,
                                              signal
                                          }) => {
    try {
        return axios.post(`${api}/product/filter/count`, {
            country_id: countryId,
            search: search,
            category_slug: categorySlug,
            subcategory_slug: subcategorySlug,
            subsubcategory_slug: subsubcategorySlug,
            json: json
        }, {
            cancelToken: signal,
            headers: {
                'x-singleton-hitno': singleton(`${api}/product/filter/count`),
                'Accept-Language': document.querySelector('html').getAttribute('lang')
            }
        })
    } catch (error) {
        notificationError()
        console.log(error)
    }
}

export const searchFilterProducts = ({
                                         countryId,
                                         search,
                                         categorySlug,
                                         subcategorySlug,
                                         subsubcategorySlug,
                                         json,
                                         page,
                                         sort
                                     }) => {
    try {
        return axios.post(`${api}/product/filter`, {
            country_id: countryId,
            search: search,
            category_slug: categorySlug,
            subcategory_slug: subcategorySlug,
            subsubcategory_slug: subsubcategorySlug,
            json: json,
            page: page,
            sort: sort
        }, {
            headers: {
                'x-singleton-hitno': singleton(`${api}/product/filter`),
                'Accept-Language': document.querySelector('html').getAttribute('lang')
            }
        })
    } catch (error) {
        notificationError()
        console.log(error)
    }
}
